import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import "../styles/app.scss";
import imgSrc from "../assets/images/fraud-prevention.jpg";
export default function Home() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the viewport width is less than a certain value
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768); // Example breakpoint: 768px
    };

    // Initial check
    checkIsMobile();

    // Listen to window resize event
    window.addEventListener("resize", checkIsMobile);

    // Cleanup function to remove the resize event listener
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);
  return (
    <>
      <Layout
        title="Fraud Webinars"
        metaDescription="HSBC Intermediaries Webinars for Fraud Prevention"
        disableWrapper={true}
      >
        <div></div>
        <div
          style={{
            backgroundImage: `url(${imgSrc})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "auto",
            height: "33vw",
            width: "100%",
            maxHeight: "300px",
          }}
        ></div>
        <div
          style={{
            padding: "25px 0",
          }}
        >
          <div className="wrapper" style={{ display: "block" }}>
            <h1 className="page-title">HSBC UK Fraud webinars </h1>
            <p>
              To register for one of the webinars, please click on your preferred option below and enter your details.
            </p>
          </div>
          ]
          <div
            className="wrapper"
            style={{
              gap: "20px",
              width: "100%",
              display: "flex",
              alignItems: "stretch",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              className="accordion__body "
              style={{
                backgroundColor: "white",
                width: isMobile ? "100%" : "calc(50% - 10px)",
                maxWidth: "100%",
              }}
            >
              <h3>
                <strong>CPD approved 2024 Mortgage Fraud Prevention</strong>
              </h3>

              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <a href="https://hsbc.zoom.us/webinar/register/WN_NFlfw6SJSLO2SXucGkH0bA#/registration">
                          12:00pm, Thursday 17<sup>th</sup> October
                        </a>
                      </p>
                    </td>
                    <td>
                      <p>
                        <a href="https://hsbc.zoom.us/webinar/register/WN_QhbozwolQ26p4eOEP8UAQQ#/registration">
                          11:30am, Monday 4<sup>th</sup> November
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <a href="https://hsbc.zoom.us/webinar/register/WN_GP1Mn54rT2aILs1uKVNI5A#/registration">
                          2:00pm, Wednesday 4<sup>th</sup> December
                        </a>
                      </p>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="accordion__body "
              style={{
                backgroundColor: "white",
                width: isMobile ? "100%" : "calc(50% - 10px)",
                maxWidth: "100%",
              }}
            >
              <h3>
                <strong>CPD approved Introduction to Mortgage Fraud</strong>
              </h3>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <a href="https://hsbc.zoom.us/webinar/register/WN_8nV3JRheR_a4cheVzgzIUA#/registration">
                          2:30pm, Thursday 10<sup>th</sup> October
                        </a>
                      </p>
                    </td>
                    <td>
                      <p>
                        <a href="https://hsbc.zoom.us/webinar/register/WN_NW4FolJNQRiKpMU1aaxBqA#/registration">
                          10:00am, Wednesday 27<sup>th</sup> November
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <a href="https://hsbc.zoom.us/webinar/register/WN_wyavU2-KTD6vFficiVbojg#/registration">
                          4:30pm, Tuesday 3<sup>rd</sup> December
                        </a>
                      </p>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
